@page {
  size: A4;
  margin-top: 1cm;
  margin-bottom: 1cm;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  #root, #root .App {
    width: 100% !important;
    overflow: visible;
  }

  .footer, .footer div, .searchBtn, .siseBtn, .printDisplayNone {
    display: none !important;
  }

  .container {
    width: 1024px !important;
    background-position: top right !important;
  }

  .selectAddrGroup {
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    margin-top: 6rem !important;
  }

  .search-address {
    display: none !important;
  }

  .print-address {
    display: flex !important;
    width: 95rem;
    font-size: 17px;
    font-weight: 700;
    align-items: center;
    outline: 1px solid #2d71c4;
    border: 0;
    padding: 1.5rem;
    border-radius: .8rem;
    margin-left: 2.4rem;
  }

  .inputJibun {
    gap: 0.5rem;
  }

  .inputJibun input {
    width: 5rem;
    font-size: 10px;
  }

  .selectAddrDetailGroup {
    justify-content: flex-start !important;
    gap: 4rem !important;
  }

  .naverMap {
    width: 103rem !important;
  }

  .buildingInfoBody tr td:first-child {
    width: 14rem !important;
  }

  .chart-wrapper {
    width: 600px;
  }

  .chart-wrapper .highcharts-container {
    width: 600px !important;
  }

  .chart-wrapper svg {
    width: 600px !important;
  }

  .selectedAddrSiseInfo > div {
    page-break-after: always !important;
  }

  .selectedAddrSiseInfoTable tbody {
    height: auto !important;
    overflow-y: visible !important;
  }

  .selectedAddrSiseInfoTable tbody tr {
    page-break-inside: avoid !important;
  }

  .selectedAddrSrInfoTable_holo tbody {
    height: auto !important;
    overflow-y: visible !important;
  }

  .selectedAddrSrInfoTable_holo tbody tr {
    page-break-inside: avoid !important;
  }
}

.main {
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
}

.footer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 8.7rem;
  background: #F1F5F9;
}

.footerBody {
  width: 120rem;
  margin: 3rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerLeft {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.footerLeft > img {
  height: 40px;
}

.inquiryBtn {
  color: white;
  padding: 12px 16px;
  gap: 10px;
  border-radius: 8px;
  background: #2D71C4;
  border: none;
}

.modalTopArea {
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20rem;
  background: #2D71C4;
  color: #FFF;
}

.closeBtn {
  width: 2rem;
  height: 2rem;
}

.modalCenterArea {
  padding: 0 3.4rem;
  display: flex;
  /* height: 55rem;
  width: 100%; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
}

.labelArea {
  width: 8.7rem;
  display: flex;
  justify-content: space-between;
  text-align: right;
  font-weight: 700;
}

.labelArea > div:nth-child(2) {
  color: #2D71C4;
}

.inquiryEmailInputArea {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 6rem;
  gap: 1.2rem;
}

.inquiryEmailInput {
  width: 36.8rem;
  display: flex;
  height: 4.2rem;
  padding: 8px 12px;
  align-items: center;
  gap: 0.5rem;
  border-radius: 8px;
  border: 1px solid #2D71C4;
  background: #FFF;
  font-size: 1.7rem;
}

.inquiryContentInputArea {
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  height: 44.6rem;
  gap: 1.2rem;
}

.inquiryContentInput {
  vertical-align: text-top;
  width: 70.6rem;
  height: 41.3rem;
  padding: 1.2rem;
  border-radius: 0.5rem;
  border: 1px solid #2D71C4;
  background: #FFF;
  resize: none;
}

.modalBottomArea {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sendInquiryBtn {
  width: 13rem;
  color: white;
  padding: 12px 16px;
  gap: 10px;
  border-radius: 8px;
  background: #2D71C4;
  border: none;
}

.container {
  width: 120rem;
  margin: 3rem auto;
  min-height: 90rem;
  background-image: url("../public/house.svg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 37rem 22.8rem;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: space-between;
  margin: 1rem 0;
  font-size: 4.6rem;
}

.header img {
  height: 5.6rem;
}

.title {
  letter-spacing: -1.38px;
}

.btnGroup {
  display: flex;
  gap: 1rem;
}

.tabBtn {
  border: 1px solid #2D71C4;
  border-radius: 2em;
  padding: 0.5rem 1.7rem;
}

.tabBtn.selected {
  background-color: #2D71C4;
  color: #FFFFFF;
  font-weight: bold;
}

.selectAddrGroup {
  display: flex;
  justify-content: space-between;
  /*gap: 1.5rem;*/
  margin-top: 4.9rem;
  padding: 0 2.4rem;
}

.itemTitle {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 2rem 0;
}

.titleBody {
  color: #084185;
}

.inputJibun {
  display: flex;
  justify-content: center;
  align-items: baseline;
  outline: 1px solid #2D71C4;
  border-radius: 0.8rem;
  padding: 1.75rem 1.4rem;
  color: #1E1E1E;
  font-size: 1.7rem;
  gap: 0.8rem;
}

.inputJibun:focus-within {
  outline: 2px solid #084185;
}

.inputJibun input {
  border: 0;
  width: 5.6rem;
  height: 2rem;
  font-size: 1.7rem;
}

.inputJibun input:focus {
  outline: none;
}

input::placeholder {
  color: #1E1E1E;
  font-size: 1.7rem;
  font-weight: 700;
  opacity: 0.28;
}

textarea::placeholder {
  font-family: 'Pretandard', sans-serif;
  color: #1E1E1E;
  font-size: 1.7rem;
  font-weight: 700;
  opacity: 0.28;
}

.searchBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  width: 11rem;
  border-radius: 0.8rem;
}

.selectAddrDetailGroup {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  border-top: 1px solid #CACFDE;
  margin-top: 2.4rem;
  padding: 2rem 2.4rem 2.4rem 2.4rem;
  height: 17.4rem;
}

.selectAddrDetailGroup.hide {
  height: 0;
  visibility: hidden;
}

.selectAddrDetailGroup > div:nth-child(1) {
  width: 46rem;
}

.selectAddrDetailGroup > div:nth-child(2) {
  width: 30rem;
}

.selectAddrDetailGroup > div:nth-child(3) {
  width: 18rem;
}

.selectItemList {
  border-radius: 0.5rem;
  outline: 1px solid #CACFDE;
  margin-top: 1.2rem;
  height: 13.9rem;
  overflow-y: auto;
}

.selectItemList::-webkit-scrollbar {
  width: 0.8rem;
}

.selectItemList::-webkit-scrollbar-thumb {
  border-radius: 0.8rem;
  background-color: #7FA7DA;
}

.selectItemList::-webkit-scrollbar-track {
  border-radius: 0.8rem;
  background-color: #E7ECF5;
}

.selectItem {
  padding: 1.4rem 1.2rem;
}

.selectItem:hover {
  background-color: #E1F0FF;
}

.selectItem.selected {
  background-color: #E1F0FF;
}

.siseBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  width: 11rem;
  border-radius: 0.8rem;
  margin-top: 3rem;
  color: #FFFFFF;
  letter-spacing: -0.38px;
  cursor: pointer;
}

.selectedAddrSiseInfo {
  border-top: 1px solid #CACFDE;
  letter-spacing: -0.48px;
}

.selectedAddrSiseInfo > div {
  margin-top: 2rem;
}

.selectedAddrSiseInfo.hide {
  height: 0;
  visibility: hidden;
}

.totInfoHeader {
  display: flex;
  justify-content: space-between;
  margin: 2.4rem 0 1.1rem 0;
}

.dataInfoHeader {
  display: flex;
  gap: 4rem;
  color: #676C76;
}

.dataInfoHeader div {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
}

.selectedAddrSiseInfoTable {
  border-collapse: collapse;
}

.selectedAddrSiseInfoTable.hide {
  display: none;
}

.selectedAddrSiseInfoTable tr > *:nth-child(1) {
  width: 14rem;
}

.selectedAddrSiseInfoTable tr > *:nth-child(2) {
  width: 22rem;
}

.selectedAddrSiseInfoTable tr > *:nth-child(3) {
  width: 18rem;
}

.selectedAddrSiseInfoTable tr > *:nth-child(4) {
  width: 12rem;
}

.selectedAddrSiseInfoTable tr > *:nth-child(5) {
  width: 9rem;
}

.selectedAddrSiseInfoTable tr > *:nth-child(6) {
  width: 18rem;
}

.selectedAddrSiseInfoTable tr > *:nth-child(7) {
  width: 18rem;
}

.selectedAddrSiseInfoTable tr > td:nth-child(1) {
  width: 14rem;
}

.selectedAddrSiseInfoTable tr > td:nth-child(2) {
  width: 22rem;
}

.selectedAddrSiseInfoTable tr > td:nth-child(3) {
  width: 18rem;
}

.selectedAddrSiseInfoTable tr > td:nth-child(4) {
  width: 12rem;
}

.selectedAddrSiseInfoTable tr > td:nth-child(5) {
  width: 9rem;
}

.selectedAddrSiseInfoTable tr > td:nth-child(6) {
  width: 18rem;
}

.selectedAddrSiseInfoTable tr > td:nth-child(7) {
  width: 17.2rem;
}

.selectedAddrSiseInfoTable thead {
  background-color: #D7DDE7;
  border: 0;
}

.selectedAddrSiseInfoTable thead tr {
  padding: 1.6rem 1.0rem;
  display: table;
  width: 100%;
}

.selectedAddrSiseInfoTable th {
  padding: 1.5rem 1.0rem;
}

.selectedAddrSiseInfoTable tbody {
  display: block;
  height: 26.7rem;
  overflow-y: auto;
}

.selectedAddrSiseInfoTable tbody::-webkit-scrollbar {
  width: 0.8rem;
  overflow: hidden;
}

.selectedAddrSiseInfoTable tbody::-webkit-scrollbar-thumb {
  border-radius: 0.8rem;
  background-color: #B1BCCD;
}

.selectedAddrSiseInfoTable tbody::-webkit-scrollbar-track {
  border-radius: 0.8rem;
  background-color: #E7ECF5;
}

.selectedAddrSiseInfoTable tbody tr {
  display: table;
  width: 100%;
  border-bottom: 1px solid #CACFDE;
}

.selectedAddrSiseInfoTable tbody tr:hover {
  background-color: #F1F4F9;
}

.selectedAddrSiseInfoTable td {
  padding: 1.6rem 1.0rem;
  max-height: 4.6rem;
  text-align: center;
  word-break: keep-all;
}

.selectedAddrSrInfoTable_holo {
  border-collapse: collapse;
}

.selectedAddrSrInfoTable_holo.hide {
  display: none;
}

.selectedAddrSrInfoTable_holo tr > *:nth-child(1) {
  width: 28rem;
}

.selectedAddrSrInfoTable_holo tr > *:nth-child(2) {
  width: 9rem;
}

.selectedAddrSrInfoTable_holo tr > *:nth-child(3) {
  width: 11rem;
}

.selectedAddrSrInfoTable_holo tr > *:nth-child(4) {
  width: 13rem;
}

.selectedAddrSrInfoTable_holo tr > *:nth-child(5) {
  width: 7rem;
}

.selectedAddrSrInfoTable_holo tr > *:nth-child(6) {
  width: 5rem;
}

.selectedAddrSrInfoTable_holo tr > *:nth-child(7) {
  width: 12rem;
}

.selectedAddrSrInfoTable_holo tr > *:nth-child(8) {
  width: 14rem;
}

.selectedAddrSrInfoTable_holo tr > *:nth-child(9) {
  width: 12rem;
}

.selectedAddrSrInfoTable_holo tr > *:nth-child(10) {
  width: 12rem;
}

.selectedAddrSrInfoTable_holo tr > td:nth-child(1) {
  width: 27.5rem;
}

.selectedAddrSrInfoTable_holo tr > td:nth-child(2) {
  width: 9rem;
}

.selectedAddrSrInfoTable_holo tr > td:nth-child(3) {
  width: 11rem;
}

.selectedAddrSrInfoTable_holo tr > td:nth-child(4) {
  width: 13rem;
}

.selectedAddrSrInfoTable_holo tr > td:nth-child(5) {
  width: 7rem;
}

.selectedAddrSrInfoTable_holo tr > td:nth-child(6) {
  width: 5rem;
}

.selectedAddrSrInfoTable_holo tr > td:nth-child(7) {
  width: 12rem;
}

.selectedAddrSrInfoTable_holo tr > td:nth-child(8) {
  width: 14rem;
}

.selectedAddrSrInfoTable_holo tr > td:nth-child(9) {
  width: 12rem;
}

.selectedAddrSrInfoTable_holo tr > td:nth-child(10) {
  width: 11.2rem;
}

.selectedAddrSrInfoTable_holo thead {
  background-color: #D7DDE7;
  border: 0;
}

.selectedAddrSrInfoTable_holo thead tr {
  padding: 1.6rem 1rem;
  display: table;
  width: 100%;
}

.selectedAddrSrInfoTable_holo th {
  padding: 1.6rem 1.0rem;
  word-break: break-word;
}

.selectedAddrSrInfoTable_holo tbody {
  display: block;
  height: 26.7rem;
  overflow-y: auto;
}

.selectedAddrSrInfoTable_holo tbody::-webkit-scrollbar {
  width: 0.8rem;
  overflow: hidden;
}

.selectedAddrSrInfoTable_holo tbody::-webkit-scrollbar-thumb {
  border-radius: 0.8rem;
  background-color: #B1BCCD;
}

.selectedAddrSrInfoTable_holo tbody::-webkit-scrollbar-track {
  border-radius: 0.8rem;
  background-color: #E7ECF5;
}

.selectedAddrSrInfoTable_holo tbody tr {
  display: table;
  width: 100%;
  border-bottom: 1px solid #CACFDE;
}

.selectedAddrSrInfoTable_holo tbody tr:hover {
  background-color: #F1F4F9;
}

.selectedAddrSrInfoTable_holo td {
  padding: 1.6rem 1.0rem;
  max-height: 4.6rem;
  text-align: center;
  word-break: break-word;
}

.selectedAddrSrInfoTable_holo td:nth-child(1) {
  text-align: start;
}

.fw300 {
  font-weight: 300;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fs10 {
  font-size: 1.0rem;
}

.fs11 {
  font-size: 1.1rem;
}

.fs12 {
  font-size: 1.2rem;
}

.fs13 {
  font-size: 1.3rem;
}

.fs14 {
  font-size: 1.4rem;
}

.fs15 {
  font-size: 1.5rem;
}

.fs16 {
  font-size: 1.6rem;
}

.fs17 {
  font-size: 1.7rem;
}

.fs19 {
  font-size: 1.9rem;
}

.fs20 {
  font-size: 2.0rem;
}

.fs24 {
  font-size: 2.4rem;
}

.bold {
  font-weight: bold;
}

.primary {
  color: #2D71C4;
}

.bgPrimary {
  background-color: #2D71C4;
}

.pointer {
  cursor: pointer;
}

.alert {
  outline: 2px solid red;
}

.btn:hover {
  background-color: #1459AE;
  color: #FFFFFF;
  font-weight: bold;
}

.btn:active {
  background-color: #084185;
  color: #FFFFFF;
  font-weight: bold;
}

.Userlogin {
  width: 60rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  color: #2c2c2c;
  text-align: center;
  bottom: 9rem;
  position: relative;
}

.Userlogin img {
  width: 50%;
  position: relative;
  /* bottom: 3rem; */
}

.loginHeader {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
  justify-content: space-between;
  margin: 1.3rem 0 3rem 0;
  font-size: 3.8rem;
}

.Userlogin .header {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.Userlogin .loginBox {
  display: flex;
  height: 11.2rem;
  justify-content: space-around;
}

.loginBox .loginForm {
  width: 32rem;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 1.4rem;
  margin: 0 15px;
}

.loginForm .formInput {
  display: flex;
  justify-content: center;
  width: 100%;
}

.loginForm .formInput > input {
  height: 4.5rem;
  font-size: 16px;
  width: 100%;
  padding-left: 8px;
  border: 1px solid #e6e6e6;
  color: #1a1818;
  word-break: keep-all;
}

.loginForm .formInput > input::placeholder {
  font-weight: 400;
}

.loginBox .loginBtnGroup {
  display: flex;
  justify-content: center;
  top: 0;
  height: 100%;
  width: 18rem;
  background: #084185;
  border-radius: 5px;
  cursor: pointer;
}

.loginBtnGroup .loginBtn {
  margin: auto;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

.selectedAddrSrnfoTable td:nth-child(1) {
  text-align: center;
}

.siseBtnGroup {
  display: flex;
  border-radius: 0.8rem;
  outline: 1px solid #2D71C4;
}

.bonGunSiseBtn {
  padding: 9px 14px;
  border-radius: 0.8rem 0 0 0.8rem;
  cursor: pointer;
}

.siseBtnGroup > div.selected {
  background: #2D71C4;
  color: white;
}

.juByunTradeSiSeBtn {
  padding: 9px 14px;
  border-radius: 0 0.8rem 0.8rem 0;
  cursor: pointer;
}

.copyRight {
  color: #676C76;
}

.iLoveCompany {
  display: flex;
  gap: 1rem;
}

.leftBorder {
  font-size: 1.2rem;
}

.footerContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 0.1rem;
}

.toggleBox {
  color: #2D71C4;
  display: inline-flex;
  padding: 0.375rem 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  border: 1px solid #2D71C4;
  background: #FFF;
}

.dataInfoHeader {
  gap: 2rem;
}

.dataInfoHeader div:nth-child(2) {
  margin-left: 4rem;
}

.naverMap {
  width: 120rem;
  height: 50rem;
}

.buildingInfoBody {
  width: 100%;
  margin: 2rem 0;
}

.buildingInfoBody table {
  width: inherit;
  height: auto;
  word-break: keep-all;
  border-top: 2px solid #B1BCCD;
  border-bottom: 2px solid #B1BCCD;
  border-collapse: collapse;
}

.buildingInfoBody tr td:first-child {
  background-color: #E7ECF5;
  width: 20rem;
  border-bottom: 2px solid #D7DDE7;
}

.buildingInfoBody tr td:first-child div {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #676C76;
}

.buildingInfoBody tr td:last-child {
  border-right: none;
  color: #1E1E1E;
  border-bottom: 2px solid #CACFDE;
}

.descBody {
  padding: 1rem;
}

.descBody > div {
  display: flex;
  gap: 3px;
}

.areaInfoBody {
  width: 100%;
  margin: 2rem 0;
}

.areaInfoBody table {
  width: inherit;
  height: auto;
  word-break: keep-all;
  border-top: 2px solid #B1BCCD;
  border-bottom: 2px solid #B1BCCD;
  border-collapse: collapse;
}

.areaInfoBody tr .thead {
  background-color: #E7ECF5;
  border-left: 2px solid #CACFDE;
  border-right: 2px solid #CACFDE;
  border-bottom: 2px solid #D7DDE7;
}

.areaInfoBody tr td:not(.thead) {
  color: #1E1E1E;
  border: 2px solid #CACFDE;
  padding: 1rem;
}

.areaInfoBody tr td:not(.thead) div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
}

.areaInfoBody tr td:not(.thead) div a {
  text-decoration: none;
  color: #1E1E1E !important;
}

.areaInfoBody tr td:not(.thead) div a:hover {
  text-decoration: underline;
}

.areaInfoBody tr td:first-child {
  border-left: none;
}

.areaInfoBody tr td:last-child {
  border-right: none;
}

.areaInfoBody tr .thead div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
}

.areaInfoBody .addr {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.areaInfoBody .addr div:first-child {
  display: flex;
  width: 4rem;
  padding: 0.6rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 8px;
  background: #2D71C4;
  color: #FFFFFF;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 100%;
}

.areaInfoBody .siseInfo {
  font-weight: 700 !important;
  margin-bottom: 0.8rem;
}

.lineHeight-14 {
  line-height: 14px; /* 87.5% */
}

.lineHeight-140 {
  line-height: 140%; /* 22.4px */
}

.areaChart {
  margin-bottom: 2rem;
}

.areaTooltipBody {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
}

.hide {
  display: none;
}

.label {
  z-index: 1 !important;
}

.highcharts-tooltip > span {
  background-color: white;
  opacity: 1;
  z-index: 9999 !important;
}

.mt2r {
  margin-top: 2rem;
}

.search-address {
  width: 97.8rem;
  height: 3rem;
  font-size: 1.7rem;
  font-weight: 500;
  align-items: center;
  border-radius: .8rem;
  display: flex;
  justify-content: space-between;
  outline: 1px solid #2d71c4;
  border: 0;
  padding: 1.5rem;
}

.print-address {
  display: none;
}
