.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  overflow: auto;
  background-color: #0005;
}

.modal {
  position: relative;
  background-color: #fff;
  width: auto;
  max-width: 120rem;
  border-radius: 2.7rem;
  overflow: visible;
}

.modal-close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 4.5rem;
  cursor: pointer;
  z-index: 101;
}

.popup-body {
  display: grid;
  box-sizing: border-box;
  padding: 3rem;
  gap: 0.4rem;
  align-content: start;
  font-size: 2rem;
  position: relative;
  overflow-y: visible;
  overflow-x: hidden;
}

.popup-body > .bold {
  font-size: 2.2rem;
  text-align: center;
}

.popup-body > hr {
  width: 54rem;
  border: 2px solid #e9eff5;
}

.result-list {
  min-height: 30rem;
  max-height: 58rem;
  overflow-y: auto;
}

.search-result {
  display: grid;
  box-sizing: border-box;
  line-height: 4rem;
  width: 54rem;
  padding: 0 2rem;
  color: #1a1818;
  font-size: 1.9rem;
  grid-template-columns: 6rem auto;
  /*padding: 0.8rem;*/
  transition: all 0.1s;
  white-space: nowrap;
  cursor: pointer;
}

.search-result:hover {
  background-color: #d7e2ef;
}

.address-item {
  white-space: nowrap;
  height: 100%;
}

.loading-modal {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #25374D7F;
  z-index: 100;
}

.loading-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}